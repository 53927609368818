import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isNil } from 'lodash';
import { withStyles } from '@material-ui/core';

import RichText from '../../shared/RichText';

import BackgroundImage from './BackgroundImage';

import withSecondaryTheme from './withSecondaryTheme';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    flexBasis: '100%',
    zIndex: '2',
    position: 'relative',
    overflow: 'hidden',
    flexGrow: '0',
    flexShrink: '0',
    backgroundColor: theme.palette.background.default,
    padding: '20px',
    minHeight: '270px',
    [theme.breakpoints.only('xs')]: {
      order: ({ data }) => {
        const order = get(data, 'mobileOrder');
        if (isNil(order)) {
          return 'inherit';
        }
        return order;
      },
    },
    [theme.breakpoints.up('sm')]: {
      padding: '70px 24px 24px 24px',
      minHeight: '620px',
    },
  },
  heroImage: {
    padding: '0',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  totalColumns1: {},
  totalColumns2: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
      padding: '24px 60px',
    },
  },
  totalColumns3: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '33.3%',
      flexBasis: '33.3%',
    },
  },
  totalColumns4: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '25%',
      flexBasis: '25%',
    },
  },
});

function Column({ classes, className, data, totalColumns, heroImage }) {
  const backgroundImage = get(data, 'backgroundImage');
  const content = get(data, 'content');
  const textAlign = get(data, 'textAlign.0');

  const totalColumnsClassName = `totalColumns${totalColumns}`;
  const textAlignClassName = `textAlign${textAlign}`;

  let backgroundColor;
  if (!isEmpty(backgroundImage)) {
    backgroundColor = 'transparent';
  }

  return (
    <div
      className={clsx(classes.root, get(classes, totalColumnsClassName), className, heroImage && classes.heroImage)}
      style={{ backgroundColor }}>
      <RichText data={content} className={get(classes, textAlignClassName)} />
      <BackgroundImage data={backgroundImage} />
    </div>
  );
}

Column.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  totalColumns: PropTypes.number,
  heroImage: PropTypes.bool,
};

Column.defaultProps = {
  classes: {},
  className: null,
  data: null,
  totalColumns: 1,
  heroImage: false,
};

export default withSecondaryTheme(withStyles(styles)(Column));
