import React from 'react';
import PropTypes from 'prop-types';

import { camelCase, get } from 'lodash';

import SecondaryThemeProvider from '../../../themes/SecondaryThemeProvider';

function withSecondaryTheme(Component) {
  return props => {
    const themeType = camelCase(get(props, 'data.columnStyle.title'));
    return (
      <SecondaryThemeProvider secondaryThemeType={themeType}>
        <Component {...props} />
      </SecondaryThemeProvider>
    );
  };
}

withSecondaryTheme.propTypes = {
  data: PropTypes.object,
};

withSecondaryTheme.defaultProps = {
  data: null,
};

export default withSecondaryTheme;
