import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, isEmpty } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import '../Content'; // import for fragment

import Row from './Row';

const styles = theme => ({
  root: {},
  contentRows: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function ContentRows({ classes, className, data: contentRows }) {
  if (isEmpty(contentRows)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.contentRows}>
        {map(contentRows, (contentRow, index) => {
          const contentRowId = get(contentRow, 'contentful_id');
          const contentRowKey = `${contentRowId}-${index}`;
          return <Row key={contentRowKey} data={contentRow} />;
        })}
      </div>
    </div>
  );
}

ContentRows.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.array,
};

ContentRows.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export const query = graphql`
  fragment ContentRowFragment on ContentfulRowStructure {
    contentful_id
    title
    backgroundColor
    backgroundImage {
      imageAltText
      imageTitleText
      cloudinaryImage {
        secure_url
        public_id
      }
    }
    columnVerticalAlignment
    columns {
      contentful_id
      title
      textAlign
      mobileOrder
      backgroundImage {
        imageAltText
        imageTitleText
        cloudinaryImage {
          secure_url
          public_id
        }
      }
      columnStyle {
        title
      }
      content {
        raw
        references {
          ...ContentReferenceFragment
        }
      }
    }
  }
`;

export default withStyles(styles)(ContentRows);
