import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isEqual } from 'lodash';
import { withStyles } from '@material-ui/core';
import { Image, Placeholder, Transformation } from 'cloudinary-react';

import useCloudinaryImageSize from '../../hooks/useCloudinaryImageSize';

const styles = theme => ({
  root: {
    display: 'block',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-1',
  },
});

function BackgroundImage({ classes, className, data }) {
  const ref = useRef();
  const { width, height } = useCloudinaryImageSize(ref);

  const altText = get(data, 'imageAltText');
  const publicId = get(data, 'cloudinaryImage.0.public_id');

  if (isEmpty(data)) {
    return null;
  }

  return (
    <Image publicId={publicId} alt={altText} ref={ref} secure="true" className={clsx(classes.root, className)}>
      <Placeholder type="blur" />
      <Transformation width={width} height={height} crop="fill" gravity="custom" fetchFormat="auto" quality="auto" />
    </Image>
  );
}

BackgroundImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

BackgroundImage.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(BackgroundImage);
