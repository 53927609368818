import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Hyphenation from '../components/shared/Hyphenation';

import SeoFields from '../components/page/SeoFields';
import Breadcrumbs from '../components/navigation/Breadcrumbs';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import ContentRows from '../components/page/ContentRows';

import useInitI18next from '../components/hooks/useInitI18next';
import useInitLuxon from '../components/hooks/useInitLuxon';

import '../components/page/HeroImage'; // for fragment

const styles = theme => ({});

function LandingPageTemplate({ classes, data }) {
  const page = get(data, 'contentfulLandingPage');
  const breadcrumbs = get(page, 'breadcrumbs');
  const contentRows = get(page, 'contentRows');

  useInitI18next();
  useInitLuxon();

  return (
    <>
      <Hyphenation />
      <SeoFields data={page} />
      <Header data={page} />
      <main>
        <Breadcrumbs data={breadcrumbs} />
        <ContentRows data={contentRows} />
      </main>
      <Footer data={page} />
    </>
  );
}

export const pageQuery = graphql`
  query LandingPageTemplate($id: String!) {
    contentfulLandingPage(id: { eq: $id }) {
      description
      menuTitle
      topNavigation {
        ...TopNavigationFragment
      }
      breadcrumbs {
        ...BreadcrumbPageFragment
      }
      footer {
        ...FooterFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      contentRows {
        ...ContentRowFragment
      }
      seoFields {
        ...SeoDataFragment
      }
    }
  }
`;

LandingPageTemplate.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};

LandingPageTemplate.defaultProps = {
  classes: {},
  data: null,
};

export default withStyles(styles)(LandingPageTemplate);
