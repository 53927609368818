import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isEmpty, isNil, isEqual, map } from 'lodash';
import { Container, withStyles } from '@material-ui/core';

import Column from './Column';
import BackgroundImage from './BackgroundImage';

const isFirstReferenceHeroImage = data => {
  const contentType = get(data, 'columns.0.content.references.0.internal.type');
  return isEqual(contentType, 'ContentfulHeroImageModule');
};

const styles = theme => ({
  root: {
    zIndex: '1',
    position: 'relative',
    overflow: 'hidden',
  },
  columns: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  container: {
    [theme.breakpoints.between('sm', theme.breakpoints.values.lg + 48)]: {
      padding: ({ data }) => {
        const hasBackgroundColor = !isNil(get(data, 'backgroundColor'));
        const hasBackgroundImage = !isNil(get(data, 'backgroundImage'));
        return hasBackgroundColor || hasBackgroundImage ? '0 24px' : '0';
      },
    },
  },
  verticalAlignTop: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  verticalAlignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  verticalAlignBottom: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

function Row({ classes, className, data }) {
  let backgroundColor = get(data, 'backgroundColor');
  const backgroundImage = get(data, 'backgroundImage');
  const columns = get(data, 'columns');
  const totalColumns = get(columns, 'length');
  const verticalAlign = get(data, 'columnVerticalAlignment.0');

  const hasHeroImage = useMemo(() => isFirstReferenceHeroImage(data), [data]);

  const verticalAlignClassName = `verticalAlign${verticalAlign}`;

  if (isEmpty(backgroundColor) || !isEmpty(backgroundImage)) {
    backgroundColor = 'transparent';
  }

  return (
    <div className={clsx(classes.root, className)} style={{ backgroundColor }}>
      <Container maxWidth={hasHeroImage ? false : 'lg'} className={classes.container}>
        <div className={classes.columns}>
          {map(columns, (column, index) => {
            const columnId = get(column, 'contentful_id');
            const columnKey = `${columnId}-${index}`;
            return (
              <Column
                className={get(classes, verticalAlignClassName)}
                key={columnKey}
                data={column}
                totalColumns={totalColumns}
                heroImage={hasHeroImage}
              />
            );
          })}
        </div>
      </Container>
      <BackgroundImage data={backgroundImage} />
    </div>
  );
}

Row.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

Row.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(Row);
